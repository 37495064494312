/*  Navigation - Covisint
--------------------------------------------------*/

.header {
    background-color: $cod-gray;
    padding: 1.5em 0em;
    &--title {
        color: $white;
        text-align: right;
        font-size: 1.5em;
        margin: 0;
    }
    .logo {
        display: inline-block;
        max-width: 150px;
        margin: 0 auto;
        @media (min-width: $screen-sm-min) {
            max-width: 175px;
            display: block;
            margin: 0;
        }
    }
}
