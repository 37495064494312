/*  Fonts - Covisint
--------------------------------------------------*/

@font-face {
    font-family: 'lato';
    src: url('../fonts/lato-light-webfont.eot');
    src: url('../fonts/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-light-webfont.woff2') format('woff2'),
         url('../fonts/lato-light-webfont.woff') format('woff'),
         url('../fonts/lato-light-webfont.ttf') format('truetype'),
         url('../fonts/lato-light-webfont.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('../fonts/lato-lightitalic-webfont.eot');
    src: url('../fonts/lato-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-lightitalic-webfont.woff') format('woff'),
         url('../fonts/lato-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/lato-lightitalic-webfont.svg#latolight_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'lato';
    src: url('../fonts/lato-italic-webfont.eot');
    src: url('../fonts/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-italic-webfont.woff2') format('woff2'),
         url('../fonts/lato-italic-webfont.woff') format('woff'),
         url('../fonts/lato-italic-webfont.ttf') format('truetype'),
         url('../fonts/lato-italic-webfont.svg#latoitalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'lato';
    src: url('../fonts/lato-regular-webfont.eot');
    src: url('../fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular-webfont.ttf') format('truetype'),
         url('../fonts/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: bold;
    font-style: normal;
}