/* Buttons - Covisnt
--------------------------------------------------*/

.btn-default {
    border: none;
    background-color: $covisint-blue;
    color: #f5f5f5;
    border-radius: 0px;
    @include transition(0.4s);
    &:hover {
        background-color: $primary-color-hover;
        color: $white;
    }
}