/*  Master - Covisint
---------------------------------------------------*/

body {
    background-color: $cod-gray;
}

.title--main {
    background-color: $covisint-blue;
    padding: 1.5em 0em;
    color: $white;
    text-align: center;
    @media (min-width: $screen-sm-min) {
        text-align: left;
    }
    h1 {
        margin: 0;
        a {
            color: $white;
            &:hover {
                color: $white;
            }
        }
    }
}


.hero {
    padding: 4em 0em;
    background-color: $cod-gray;
    background-size: cover;
    color: $white;
    font-size: 1.275em;
    h1 {
        color: $covisint-blue;
        @media (min-width: $screen-md-min) {
            font-size: 2.3em;
        }
    }
}

.section {
    &--main {
        background-color: $white;
        padding: $default-padding;
    }
    &--alt {
        background-color: $white-sand;
        padding: $default-padding;
    }
}

.panel-default {
    .panel-heading {
        color: $white;
        background-color: $covisint-blue;
        .panel-title {
            font-size: 1.3em;
        }
    }
}
