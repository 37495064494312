/*  Colors - Covisint
--------------------------------------------------*/


/* 01. Primary Colors */

$covisint-blue: #00a3e0;
$bright-turquoise:#00d6f5;
$cerulean: #0cb7eb;
$jordy-blue: #87cdf1;
$endeavour: #005eb8;
$oslo-gray: #888b8d;
$ship-gray: #414042;


/* 02. Secondary Colors */

$orange: #ff8200;
$yellow: #ffc600;
$green: #97d700;
$manatee: #9a9ea3;

/* 03. Global Grayscale Colors */

$white: #ffffff;
$white-sand: #f5f5f5;
$gallery: #eeeeee;
$silver: #cccccc;
$dusty-gray: #999999;
$boulder: #777777;
$mine-shaft: #333333;
$cod-gray: #2a2a2a;


// Global Colors
$header-font-color: $mine-shaft;
$body-font-color: $ship-gray;

// We use these as default colors throughout
$primary-color: $covisint-blue;
$primary-color-hover: scale-color($covisint-blue, $lightness: 35%);
// $secondary-color: #e7e7e7;
// $alert-color: #f04124;
// $success-color: #43AC6A;
// $warning-color: #f08a24;
// $info-color: #a0d3e8;


 