/* Footer - Covisnt
--------------------------------------------------*/

.footer {
    background-color: $cod-gray;
    padding: $default-padding;
    color: white;
    text-align: center;
    a {
        color: $white;
        &:hover {
            color: $covisint-blue;
        }
    }
    &--message {
        padding: 3.5em 0em;
        background-color: $white-sand;
        h2 {
            text-align: center;
        }
    }
}
