/*  Typography - Covisint
--------------------------------------------------*/

$heading-font-family: 'lato', 'helvetica-light',
"HelveticaNeue-Light",
"Helvetica Neue Light",
"Helvetica Neue",
Helvetica,
Arial,
"Lucida Grande",
sans-serif;
$body-font-family: 'lato', 'helvetica-light',
"HelveticaNeue-Light",
"Helvetica Neue Light",
"Helvetica Neue",
Helvetica,
Arial,
"Lucida Grande",
sans-serif;
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    font-weight: normal;
}

h1 {
    letter-spacing: -1px;
}

h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

body {
    font-family: $body-font-family;
    color: $body-font-color;
    font-weight: normal;
}

p {}

a {
    color: $primary-color;
    &:hover {
        color: $primary-color-hover;
        text-decoration: none;
        cursor: pointer;
        @include transition(0.4s);
    }
    &:active,
    &:visited {
        // color: $orange;
        text-decoration: none;
    }
}
